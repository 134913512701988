import { api } from '../http/http';
import { DefaultCallback, DefaultRes, CustomerTypes } from '../types';
import { store } from '../store';
import { AxiosError } from 'axios';

interface CreateUserDto {
    name: string,
    businessCode: string,
    address: string,
    city: string,
    state: string,
    country: string,
    email: string,
    zipCode: string,
    phone: string,
}

export class CustomerService {
    public static readonly dispatch = store.dispatch.customer

    public static async createUser(dto: CreateUserDto, callback?: DefaultCallback) {
        const { dispatch: { toggleLoading } } = CustomerService

        try {
            toggleLoading('create', true)
            console.log('createUser')
            const { data: { isValid, message, result } } = await api.post<DefaultRes<CustomerTypes.Model>>('/customer', dto)
            console.log('aqui', { isValid, message, result })

            toggleLoading('create', false)

            return callback && callback(isValid, message)
        } catch (error: any) {
            console.log('ae', error)
            toggleLoading('create', false)

            if (error.response) {
                const ERROR_CODE = (error as AxiosError<DefaultRes<CustomerTypes.Model>>).response.data.message ?? JSON.stringify((error as AxiosError<DefaultRes<CustomerTypes.Model>>).response.data.messages)
                console.log('ERROR_CODE', ERROR_CODE)
                return callback && callback(false, ERROR_CODE)
            }

            return callback && callback(false, String(error))
        }
    }

    public static async listCustomers(page?: number, callback?: DefaultCallback) {
        const { dispatch: { toggleLoading } } = CustomerService
        try {
            toggleLoading('fetchMany', true)

            const { data: { isValid, message, result } } = await api.get<DefaultRes<CustomerTypes.Model>>('/customer')

            CustomerService.dispatch.setPagination({ items: result }, {})

            toggleLoading('fetchMany', false)

            return callback && callback(isValid, message)

        } catch (error) {
            toggleLoading('create', false)

            if (error.response) {
                const ERROR_CODE = (error as AxiosError<DefaultRes<CustomerTypes.Model>>).response.data.message ?? JSON.stringify((error as AxiosError<DefaultRes<CustomerTypes.Model>>).response.data.messages)
                console.log('ERROR_CODE', ERROR_CODE)
                return callback && callback(false, ERROR_CODE)
            }

            return callback && callback(false, String(error))
        }
    }
}