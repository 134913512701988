import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

interface Props {
  align?: string,
  cover: string,
}

const Icon = <LoadingOutlined style={{ fontSize: 35 }} spin />

const Loading: React.FC<Props> = ({
  align = 'center',
  cover = 'inline'
}) => {
  return (
    <div className={`loading text-${align} cover-${cover}`}>
      <Spin indicator={Icon} />
    </div>
  );
}

export default Loading;