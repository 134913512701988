import React from 'react'
import { Row, Col, Card } from "antd";

import { LoginForm } from '../../components/forms/login-form';

const backgroundURL = '/img/others/img-22.png'
const backgroundStyle = {
  backgroundImage: `url(${backgroundURL})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover'
}

export const LoginScreen = () => {

  return (
    <div className="h-100" style={backgroundStyle}>
      <div className="container d-flex flex-column justify-content-center h-100">
        <Row justify="center">
          <Col xs={20} sm={20} md={20} lg={7}>
            <Card>
              <div className="my-4">
                <div className="text-center">
                  <img className="img-fluid" width={300} src={`/img/others/logo-easy-billet.png`} alt="" />
                  <p></p>
                  <p>Don't have an account yet? <a href="/auth/register-1">Sign Up</a></p>
                </div>
                <Row justify="center">
                  <Col xs={24} sm={24} md={20} lg={20}>
                    <LoginForm />
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}