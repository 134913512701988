/* jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Drawer, Layout } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

import MenuContent from './MenuContent';
import Flex from '../../../components/flex';

interface Props {
  handleClose(): void,
  isMobile: boolean,
  visible: boolean
}

const { Sider } = Layout;

const SideBar: React.FC<Props> = ({
  handleClose,
  isMobile,
  visible,
}) => {
  if (isMobile) {
    return (
      <Drawer
        placement="left"
        closable={false}
        onClose={handleClose}
        visible={visible}
        bodyStyle={{ padding: 5 }}
      >
        <Flex flexDirection="column" className="h-100">
          <>
            <Flex justifyContent="between" alignItems="center">
              <img height="150" width="150" />
            </Flex>
            <div className="mobile-nav-menu">
              <MenuContent />
              <div className="nav-close" onClick={handleClose}>
                <ArrowLeftOutlined />
                <span>Close menu</span>
              </div>
            </div>
          </>
        </Flex>
      </Drawer>
    );
  }

  return (
    <Sider
      className="side-nav"
      width={200}
      collapsed={visible}
    >
      <MenuContent />
    </Sider>
  );
};

export default SideBar;
