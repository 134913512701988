import { store } from '../store';
import { DefaultCallback, DefaultRes, UserTypes } from '../types';
import { api } from '../http/http';
import { AxiosError, AxiosResponse } from 'axios';

export class AuthService {
  public static readonly dispatch = store.dispatch.user;

  public static destroySession() {
    const { dispatch: { destroyUser } } = AuthService
  }

  public static async login(
    email: string,
    password: string,
    callback?: DefaultCallback
  ) {
    const { dispatch: { toggleLoading, auth } } = AuthService

    try {
      toggleLoading('login', true)

      const { data: { isValid, message, result } } = await api.post<DefaultRes<UserTypes.Model>, AxiosResponse<DefaultRes<{ user: UserTypes.Model, token: string }>>>('/user/signin', { email, password })

      console.log('aqui', { isValid, message, result })

      toggleLoading('login', false)

      if (isValid) {
        console.log('resut', result)
        auth(result?.user, result?.token)
      }

      return callback && callback(isValid, message)

    } catch (error) {
      console.log(error)
      toggleLoading('login', false)

      if (error.response) {
        const ERROR_CODE = (error as AxiosError<DefaultRes<UserTypes.Model>>).response?.data?.messages

        return callback && callback(false, ERROR_CODE)
      }

      return callback && callback(false, String(error))

    }
  }
}