import type { RootModel } from '.';
import { createModel } from "@rematch/core";

import { PaginationFactory } from '../factory';
import { CustomerTypes } from '../types';

export interface CustomerStoreProps {
    user: CustomerTypes.Model,
    pagination: PaginationFactory<CustomerTypes.Model>,
    loading: {
        fetchById: boolean,
        fetchMany: boolean,
        destroy: boolean,
        create: boolean,
        update: boolean,
    },
};
export const customer = createModel<RootModel>()({
    state: {
        customer: {} as CustomerTypes.Model,
        pagination: {
            items: [] as CustomerTypes.Model[],
        } as PaginationFactory<CustomerTypes.Model>,
        loading: {
            fetchById: false,
            fetchMany: false,
            destroy: false,
            login: false,
            create: false,
            update: false,
        },
    },
    reducers: {
        toggleLoading: (
            store, prop: keyof CustomerStoreProps['loading'], v: boolean) => {
            store.loading[prop] = v;
        },
        addCustomer:
            (store, user: CustomerTypes.Model) => {
                store.pagination.items = [user].concat(store.pagination.items);
            },
        updateUser:
            (store, customer: CustomerTypes.Model) => {
                store.pagination.items = store.pagination.items?.map(i => {
                    if (i.id === customer.id) {
                        return customer;
                    }
                    return i;
                });
            },
        setPagination:
            (store, pagination: CustomerStoreProps['pagination']) => {
                store.pagination = pagination;
            },
        destroyUser:
            (store, id: string) => {
                store.pagination.items = store.pagination.items.filter(i => i.id !== id);
            },
    },
});
