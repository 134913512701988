import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    Button,
    Cascader,
    DatePicker,
    Form,
    Input,
    InputNumber,
    Radio,
    Select,
    Switch,
    TreeSelect,
    message,
    Alert,
    Card
} from 'antd';
import brlBanks from '../../mocks/brlBanks.json'
import { RootState } from '~/store';
import { CustomerService } from '../../services/customer';
import { motion } from "framer-motion";

type SizeType = Parameters<typeof Form>[0]['size'];

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const App: React.FC = () => {
    const [componentSize, setComponentSize] = useState<SizeType | 'default'>('default');
    const { loading } = useSelector((state: RootState) => state.customer)
    const [errorMessage, setErrorMessage] = useState('');

    const onFormLayoutChange = ({ size }: { size: SizeType }) => {
        setComponentSize(size);
    };

    const prefixSelector = (
        <Form.Item name="prefix" noStyle>
            <Select style={{ width: 70 }}>
                <Select.Option value="55">+55</Select.Option>
            </Select>
        </Form.Item>
    );

    return (
        <>
            <Card>
                <motion.div
                    initial={{ opacity: 0, marginBottom: 0 }}
                    animate={{
                        opacity: errorMessage ? 1 : 0,
                        marginBottom: errorMessage ? 20 : 0
                    }}>
                    <Alert type="error" showIcon message={errorMessage}></Alert>
                </motion.div>
                <div style={{ marginBottom: 50 }}>
                    <h2>Informe os dados</h2>
                </div>
                <div>

                    <Form
                        {...layout}
                        layout="horizontal"
                        initialValues={{ size: componentSize }}
                        onFinish={async (values) => {
                            console.log(values)
                            await CustomerService.createUser(
                                {
                                    ...values,
                                    phone: `${values.prefix}${values.phone}`,
                                    country: 'Brasil'
                                }, (isValid, msg) => {
                                    console.log('to no callback', isValid, msg)
                                    if (!isValid) {
                                        setErrorMessage(msg);
                                    } else {
                                        message.success('User created successfully 😄')
                                        setErrorMessage('');
                                    }
                                })
                        }}
                        onValuesChange={onFormLayoutChange}
                        onSubmitCapture={() => console.log('submit')}
                        size='middle'
                        style={{ maxWidth: 600 }}
                    >
                        <Form.Item label="Nome" name="name"
                            rules={[{
                                required: true, message: "Por favor informe o nome!"
                            }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="phone"
                            label="Telefone"
                            rules={[{ required: true, message: 'Por favor informe o telefone!' }]}
                        >
                            <Input addonBefore={prefixSelector} style={{ width: '100%' }} />
                        </Form.Item>
                        <Form.Item name="email" label="Email" rules={[{ type: 'email', required: true, message: 'Informe um email válido!' }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item label="Cnpj" name="businessCode"
                            rules={[{ required: true, message: 'Por favor informe CNPJ!' }]}
                        >
                            <Input />
                        </Form.Item>
                        {/* <Form.Item label="Selecione o banco" name="bank"
                            rules={[{ required: true, message: 'Please select your bank!' }]}
                        >
                            <Select>
                                {brlBanks.map((bank) => (
                                    <Select.Option value={bank.bankCompe}>{bank.bankCompe} - {bank.bankName}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item> */}
                        <Form.Item label="Código postal" name="zipCode"
                            rules={[{ required: true, message: 'Por favor informe cep!' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item label="Endereço" name="address" rules={[{
                            required: true, message: "Por favor informe endereço"
                        }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item label="Cidade" name="city" rules={[{
                            required: true, message: "Por favor informe a cidade"
                        }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item label="Estado" name="state" rules={[{
                            required: true, message: "Por favor informe o estado"
                        }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item label="Pais" name="country">
                            <Input defaultValue={'Brasil'} value="Brasil" disabled />
                        </Form.Item>
                        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }} style={{ marginTop: 100 }}>
                            <Button type="primary" htmlType="submit" block loading={loading.create}>
                                Criar
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Card>

        </>
    );
};

export default App;