import React from 'react';
import { Menu, Dropdown, Avatar, Space } from 'antd';
import {
  LogoutOutlined,
  UserOutlined,
  DownOutlined
} from '@ant-design/icons';
import { uniqueId } from 'lodash';
import Icon from '../../../components/icon';
import type { MenuProps } from 'antd';
import { store } from '../../../store';

const logout = () => {
  const dispatch = store.dispatch.user

  dispatch.logout({}, {})
}

const menuItem = [
  {
    title: 'My Profile',
    icon: UserOutlined,
    path: '/',
  },
];

const items: MenuProps['items'] = [
  {
    icon: <UserOutlined />,
    label: 'Perfil',
    key: '0',
  },
  {
    type: 'divider',
  },
  {
    icon: <LogoutOutlined />,
    label: 'Deslogar',
    key: '2',
    onClick: () => logout()
  },
];

const NavProfile: React.FC = () => {
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar size={45} src="/img/others/avataaars.png" />
          <div className="pl-3">
            <h4 className="mb-0">Vampeta da Silva</h4>
            <span className="text-muted">
              Gerente
            </span>
          </div>
        </div>
        <div className="nav-profile-body">
          <Dropdown menu={{ items }} trigger={['click']}>
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                Click me
                <DownOutlined />
              </Space>
            </a>
          </Dropdown>
        </div>
      </div>
    </div>
  );

  return (
    <Dropdown placement="bottomRight" menu={{ items }} trigger={['click']}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item>
          <Avatar size={45} src="/img/others/avataaars.png" />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

export default NavProfile;
