import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Divider, Alert, message } from "antd";
import * as Yup from 'yup';
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import { motion } from "framer-motion"
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { AuthService } from '../../../services/auth';

export const LoginForm = () => {
  const navigate = useNavigate()
  const { loading } = useSelector((state: RootState) => state.user)
  const [errorMessage, setErrorMessage] = useState('');

  return (
    <>
      <motion.div
        initial={{ opacity: 0, marginBottom: 0 }}
        animate={{
          opacity: errorMessage ? 1 : 0,
          marginBottom: errorMessage ? 20 : 0
        }}>
        <Alert type="error" showIcon message={errorMessage}></Alert>
      </motion.div>
      <Form
        layout="vertical"
        name="login-form"
        onFinish={async (values) => {
          await AuthService.login(values.email, values.password, (isValid, msg) => {
            if (!isValid) {
              setErrorMessage(msg)
            } else {
              message.success('Login realizado com sucesso 😄');
              // navigate('/');
            }
          })
        }}
      >
        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              required: true,
              message: 'Please input your email',
            },
            {
              type: 'email',
              message: 'Please enter a validate email!'
            }
          ]}>
          <Input prefix={<MailOutlined className="text-primary" />} />
        </Form.Item>
        <Form.Item
          name="password"
          label={
            <div>
              <span>Password</span>
            </div>
          }
          rules={[
            {
              required: true,
              message: 'Please input your password',
            }
          ]}
        >
          <Input.Password prefix={<LockOutlined className="text-primary" />} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" block loading={loading.login}>
            Sign In
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}