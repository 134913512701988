import { api } from '../http/http';
import { DefaultCallback, DefaultRes, BillTypes } from '../types';
import { store } from '../store';
import { AxiosError } from 'axios';

export class BillService {
    public static readonly dispatch = store.dispatch.bill

    public static async listBills(page = 1, perPage = 10, id?: string, callback?: DefaultCallback) {
        const { dispatch: { toggleLoading } } = BillService

        try {
            toggleLoading('fetchMany', true)

            const { data: { isValid, message, result } } = await api.get<DefaultRes<BillTypes.Model>>('/bill', {
                params: {
                    page, perPage, id
                }
            })

            BillService.dispatch.setPagination({ items: result }, {})

            toggleLoading('fetchMany', false)

            return callback && callback(isValid, message)

        } catch (error) {
            toggleLoading('fetchMany', false)

            if (error.message) {
                const ERROR_CODE = (error as AxiosError<DefaultRes<BillTypes.Model>>).response.data.message ?? JSON.stringify((error as AxiosError<DefaultRes<BillTypes.Model>>).response.data.messages)
                console.log('ERROR_CODE', ERROR_CODE)
                return callback && callback(false, ERROR_CODE)
            }
            return callback && callback(false, String(error))
        }

    }

    public static async createBill(bill: BillTypes.Create, callback?: DefaultCallback) {
        const { dispatch: { toggleLoading } } = BillService

        try {
            toggleLoading('create', true)

            const { data: { isValid, message, result } } = await api.post<DefaultRes<BillTypes.Create>>('/bill', bill)

            BillService.dispatch.addBill(result, {})

            toggleLoading('create', false)

            return callback && callback(isValid, message)

        } catch (error) {
            toggleLoading('create', false)

            if (error.message) {
                const ERROR_CODE = (error as AxiosError<DefaultRes<BillTypes.Create>>).response.data.message ?? JSON.stringify((error as AxiosError<DefaultRes<BillTypes.Create>>).response.data.messages)
                console.log('ERROR_CODE', ERROR_CODE)
                return callback && callback(false, ERROR_CODE)
            }
            return callback && callback(false, String(error))
        }
    }
}