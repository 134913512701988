import type { RootModel } from '.';
import { createModel } from "@rematch/core";

import { PaginationFactory } from '../factory';
import { BillTypes } from '../types';

export interface BillStoreProps {
    bill: BillTypes.Model,
    create: BillTypes.Create,
    pagination: PaginationFactory<BillTypes.Model>,
    loading: {
        fetchById: boolean,
        fetchMany: boolean,
        destroy: boolean,
        create: boolean,
        update: boolean,
    },
};

export const bill = createModel<RootModel>()({
    state: {
        bill: {} as BillTypes.Model,
        create: {} as BillTypes.Create,
        pagination: {
            items: [] as BillTypes.Model[],
        } as PaginationFactory<BillTypes.Model>,
        loading: {
            fetchById: false,
            fetchMany: false,
            destroy: false,
            login: false,
            create: false,
            update: false,
        },
    },
    reducers: {
        toggleLoading: (
            store, prop: keyof BillStoreProps['loading'], v: boolean) => {
            store.loading[prop] = v;
        },
        addBill:
            (store, bill: BillTypes.Model) => {
                store.pagination.items = [bill].concat(store.pagination.items);
            },
        updateUser:
            (store, bill: BillTypes.Model) => {
                store.pagination.items = store.pagination.items?.map(i => {
                    if (i.id === bill.id) {
                        return bill;
                    }
                    return i;
                });
            },
        setPagination:
            (store, pagination: BillStoreProps['pagination']) => {
                store.pagination = pagination;
            },
        destroyUser:
            (store, id: string) => {
                store.pagination.items = store.pagination.items.filter(i => i.id !== id);
            },
    },
});
