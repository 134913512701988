import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { store } from '../store';
import { DefaultRes } from '../types';

export const ENVS: any = {
  dev: {
    BASE_URL: 'http://localhost:3000/api/v1',
  },
  stg: {
    BASE_URL: 'http://localhost:3333/api/v1/',
  },
  prd: {
    BASE_URL: 'https://ex.easybillet.solutions/api/v1',
  },
};
export const api = axios.create({
  baseURL: ENVS.prd.BASE_URL,
  // baseURL: ENVS[process.env.NODE_ENVI || 'dev'].BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

export const sanitalizeApiException = (error: any) => {
  if (error?.response) {
    const errorCode = error.response?.data?.message;

    return `api.${errorCode}`;
  }

  return 'api.UNKNOWN';
}


const onRequest = (axiosConfig: AxiosRequestConfig): AxiosRequestConfig => {
  return {
    ...axiosConfig,
    headers: {
      'authorization': `${store.getState().user.token}`,
    }
  };
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => Promise.reject(error);

const onResponseError = (error: AxiosError<DefaultRes>): Promise<AxiosError> => {
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse<DefaultRes>) => {
  return response;
};
// @ts-ignore
api.interceptors.request.use(onRequest, onRequestError);
// @ts-ignore
api.interceptors.response.use(onResponse, onResponseError);
