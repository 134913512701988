import React from 'react';
import { Menu } from 'antd';
import {
  UserOutlined,
  UngroupOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

interface MenuItemProps {
  label: string,
  route?: string,
  icon?: JSX.Element,
}

const MenuItem: React.FC<MenuItemProps> = ({
  label,
  route,
  icon,
  ...props
}) => {
  const navigate = useNavigate();

  const handleNavigate = () => navigate(route);

  return (
    <Menu.Item onClick={handleNavigate} className="mr-1" {...props}>
      {icon}
      <span>
        {label}
      </span>
    </Menu.Item>
  );
};

const MenuContent: React.FC = () => {
  return (
    <Menu
      theme="light"
      mode="inline"
      style={{ height: '100%', borderRight: 0 }}
      defaultOpenKeys={['/app/dashboard/core/user/list']}
    >
      <Menu.ItemGroup title="App">
        {/* <Menu.SubMenu title="Users" icon={<UserOutlined />}>
          <MenuItem
            key="/app/dashboard/core/user/list"
            route="/app/dashboard/core/user/list"
            label="List"
          />
        </Menu.SubMenu> */}
        <Menu.SubMenu title="Clientes" icon={<UngroupOutlined />}>
          <MenuItem
            key="/customer/list"
            route="/customer/list"
            label="Listar"
          />
          <MenuItem
            key="/customer/create"
            route="/customer/create"
            label="Criar"
          />
        </Menu.SubMenu>
        <Menu.SubMenu title="Boleto" icon={<UngroupOutlined />}>
          <MenuItem
            key="/bill/list"
            route="/bill/list"
            label="Listar"
          />
          <MenuItem
            key="/bill/create"
            route="/bill/create"
            label="Criar"
          />
        </Menu.SubMenu>
      </Menu.ItemGroup>
    </Menu>
  );
};

export default MenuContent;
