import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Main from '../layouts/main/index';
import { RootState } from '../store';
import AuthLayout from '../layouts/auth';
import { LoginScreen } from '../screen/auth/login.screen';
import Loading from '../components/loading';

// CUSTOMER
import CustomerCreate from '../screen/customer/customer.create';
import CustomerList from '../screen/customer/customer.list';
import BillEmission from '../screen/bill/bill.create';
import BillList from '../screen/bill/bill.list';

const DashboardScreen = React.lazy(() => import("../screen/dashboard/index"));

export const AppRoutes = () => {
  const { authUser, token } = useSelector((state: RootState) => state.user);

  const IS_AUTHENTICATED = !!authUser && !!token;

  console.log('IS_AUTHENTICATED', IS_AUTHENTICATED)

  if (!IS_AUTHENTICATED) {
    return (
      <Suspense fallback={<Loading cover="content" />}>
        <Routes>
          <Route path="/" element={<AuthLayout />}>
            <Route path="/" element={<LoginScreen />} />
            {/* <Route path="/register" element={<RegisterScreen />} /> */}
          </Route>
        </Routes>
      </Suspense>
    )
  }

  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Routes>
        <Route path="/" element={<Main />}>
          <Route path="/" element={<CustomerList />} />
          {/* <Route path="/" element={<DashboardScreen />} /> */}
          <Route path="/customer/create" element={<CustomerCreate />} />
          <Route path="/customer/list" element={<CustomerList />} />
          <Route path="/bill/list" element={<BillList />} />
          <Route path="/bill/create" element={<BillEmission />} />
        </Route>
      </Routes>
    </Suspense>
  )

}