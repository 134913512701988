import React, { useState } from 'react';
import { SettingOutlined } from '@ant-design/icons';
import { Drawer, Menu } from 'antd';

const NavSettingsPanel: React.FC = () => {
  const [visible, setVisible] = useState(false);

  const showDrawer = () => setVisible(true);
  const hideDrawer = () => setVisible(false);

  return (
    <>
      <Menu mode="horizontal">
        <Menu.Item onClick={showDrawer}>
          <SettingOutlined className="nav-icon mr-1" />
        </Menu.Item>
      </Menu>
      <Drawer
        title="Configurações"
        placement="right"
        width={350}
        onClose={hideDrawer}
        visible={visible}
      />
    </>
  );
};

export default NavSettingsPanel;
