import React, { useEffect, useState } from 'react';
import { Button, Table, Card, Input, Menu, message, Tag, Space, Divider } from 'antd';
import Flex from '../../components/flex/';
import moment from 'moment';

import { DownloadOutlined, SearchOutlined, DeleteOutlined, FileTextOutlined, EditOutlined, PhoneOutlined } from '@ant-design/icons';
import { BillService } from '../../services/bill';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { RegexFactory } from '../../factory/regex.factory';
import { EllipsisDropdown } from '../../components/ellipsis-dropdown';

const App: React.FC = () => {
    const { pagination: billPagination, loading } = useSelector((state: RootState) => state.bill);

    const [filter, setFilter] = useState({ id: '' });
    const [pagination, setPagination] = useState({
        page: 1,
        perPage: 15,
    });

    const onFilter = async (clear = false) => {
        if (clear) {
            setFilter({ id: '' });
        }

        await BillService.listBills(pagination.page, pagination.perPage, filter.id)
        // await leadService.getMany({ ...pagination, filter: clear ? {} as LeadTypes.Filters : { ...filter } });
    }

    const downloadPDF = async (data: JSON) => {
        console.log(data)
        // base64 to pdf
        const linkSource = `data:application/pdf;base64,${data}`;
        const downloadLink = document.createElement("a");
        const fileName = "boleto.pdf";

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    useEffect(() => {
        BillService.listBills(pagination.page, pagination.perPage)
        console.log(billPagination.items)
    }, [pagination]);

    return (<>
        <Card>
            <Flex alignItems="center" justifyContent="between" mobileFlex={false}>
                <>
                    <Flex className="mb-1" mobileFlex={false}>
                        <Space>
                            <div className="mr-md-6 mb-3">
                                <Input
                                    value={filter.id}
                                    onChange={e => setFilter({ ...filter, id: e.currentTarget.value })}
                                    placeholder="Pesquisar por ID"
                                    prefix={<SearchOutlined />}
                                />
                            </div>
                        </Space>
                    </Flex>
                    <Flex flexDirection='row-reverse'>
                        <Space>
                            <Button type="default" className='mr-2' block onClick={async () => await onFilter(true)}>Limpar filtro</Button>
                            <Button type="primary" block onClick={async () => await onFilter(false)}>
                                <span>Buscar</span>
                                <SearchOutlined />
                            </Button>
                        </Space>
                    </Flex>
                </>
            </Flex>
            <div className="table-responsive">
                {/* <p>{JSON.stringify(billPagination.items)}</p> */}
                <Table
                    columns={[
                        {
                            title: 'ID',
                            dataIndex: 'id',
                        },
                        {
                            title: 'Valor',
                            dataIndex: 'amount',
                            render: (_, dep) => "R$ " + dep.amount
                        },
                        {
                            title: 'Vencimento',
                            dataIndex: 'dueDate',
                            render: (_, dep) => moment(dep.dueDate).format('DD/MM/YYYY')
                        },
                        {
                            title: 'Data de criação',
                            dataIndex: 'createdAt',
                            render: (_, dep) => moment(dep.createdAt).format('DD/MM/YYYY HH:mm')
                        },
                        {
                            title: 'Última atualização',
                            dataIndex: 'updatedAt',
                            render: (_, dep) => moment(dep.updatedAt).format('DD/MM/YYYY HH:mm')
                        },
                        {
                            title: '',
                            dataIndex: 'actions',
                            render: (_, dep) => (
                                <div className="text-right">
                                    <EllipsisDropdown
                                        menu={
                                            <Menu>
                                                <Menu.Item key="1" onClick={(e) => {
                                                    // setSelectedLead(dep);
                                                    // onDeletePress();
                                                    downloadPDF(dep.base64);
                                                }}>
                                                    <DownloadOutlined />
                                                    <span className="ml-2">Download PDF</span>
                                                </Menu.Item>
                                                <Menu.Divider />
                                                <Menu.Item key="1" onClick={() => {
                                                    // setSelectedLead(dep);
                                                    // onDeletePress();
                                                }}>
                                                    <FileTextOutlined />
                                                    <span className="ml-2">Download Remessa</span>
                                                </Menu.Item>
                                                <Menu.Divider />
                                                <Menu.Item key="1" onClick={() => {
                                                    // setSelectedLead(dep);
                                                    // onDeletePress();
                                                }}>
                                                    <DeleteOutlined />
                                                    <span className="ml-2">Excluir</span>
                                                </Menu.Item>
                                            </Menu>
                                        }
                                    />
                                </div>
                            )
                        }
                    ]}
                    dataSource={billPagination?.items}
                >
                </Table>
            </div>
        </Card>
    </>)
}


export default App