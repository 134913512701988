// jsx-a11y/click-events-have-key-events
// jsx-a11y/no-static-element-interactions
// jsx-a11y/no-noninteractive-element-interactions
// jsx-a11y/no-noninteractive-element-interactions
import React from 'react';
import { Layout } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH } from '../../../constants/theme.constants';
import NavSettingsPanel from './NavSettingsPanel';
import NavProfile from './NavProfile';

const { Header } = Layout;

interface Props {
  toggleSideBar(): void,
  sidebarCollapsed: boolean,
}

const HeaderNav: React.FC<Props> = ({
  toggleSideBar,
  sidebarCollapsed,
}) => {
  const getNavWidth = () => {
    if (sidebarCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
    }
    return `${SIDE_NAV_WIDTH}px`;
  };

  return (
    <Header className="app-header">
      <div className="app-header-wrapper">
        <div className="logo" onClick={toggleSideBar}>
          <img className="img-fluid" width={200} src={`/img/others/logo-easy-billet.png`} alt="" />
        </div>
        <div className="nav" style={{ width: `calc(100% - ${getNavWidth()})` }}>
          <div className="nav-left">
            <ul className="ant-menu ant-menu-root ant-menu-horizontal">
              <li className="ant-menu-item ant-menu-item-only-child" onClick={toggleSideBar}>
                {sidebarCollapsed
                  ? <MenuUnfoldOutlined className="nav-icon" />
                  : <MenuFoldOutlined className="nav-icon" />}
              </li>
            </ul>
          </div>
          <div className="nav-right">
            <NavSettingsPanel />
            <NavProfile />
          </div>
        </div>
      </div>
    </Header>
  );
};

export default HeaderNav;
