import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import formatar from 'formatar-valores'
import {
    Button,
    DatePicker,
    Form,
    Input,
    Alert,
    Card,
    message,
} from 'antd';
import { motion } from "framer-motion";
import { BillService } from '../../services/bill';
import moment from 'moment';

const customDisabledStyle = { fontWeight: 'bold', color: 'gray' }

const BillEmission: React.FC = () => {
    const [form] = Form.useForm();
    const { loading } = useSelector((state: RootState) => state.bill)
    const [errorMessage, setErrorMessage] = useState('');

    const getDataFromCep = async (cep: string, prefix = '') => {
        try {
            const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`)
            const data = await response.json()

            if (data) {
                form.setFieldValue(`${prefix}.street`, data.logradouro)
                form.setFieldValue(`${prefix}.neighborhood`, data.bairro)
                form.setFieldValue(`${prefix}.city`, data.localidade)
                form.setFieldValue(`${prefix}.state`, data.uf)
            } else {
            }
        } catch (error) {

        }
    }

    return (
        <>
            <Card>
                <motion.div
                    initial={{ opacity: 0, marginBottom: 0 }}
                    animate={{
                        opacity: errorMessage ? 1 : 0,
                        marginBottom: errorMessage ? 20 : 0
                    }}>
                    <Alert type="error" showIcon message={errorMessage}></Alert>
                </motion.div>
                <div style={{ marginBottom: 50 }}>
                    <h2>Pagador</h2>
                    <hr></hr>
                </div>

                <Form
                    form={form}
                    size='middle'
                    style={{ maxWidth: 600 }}
                    layout="horizontal"
                    onFinish={(values) => {
                        console.log(values['payer.name'])
                        BillService.createBill({
                            pagador: {
                                nome: values['payer.name'],
                                endereco: {
                                    bairro: values['payer.neighborhood'],
                                    cep: values['payer.zipCode'],
                                    cidade: values['payer.city'],
                                    estadoUF: values['payer.state'],
                                    logradouro: values['payer.street'],
                                },
                                RegistroNacional: '12345678'
                            },
                            beneficiario: {
                                nome: values['recipient.name'],
                                cnpj: values['recipient.cnpj'],
                                dadosBancarios: {
                                    agencia: String(values['recipient.branch']).slice(0, -1),
                                    agenciaDigito: values['recipient.branch'][values['recipient.branch'] - 1],
                                    conta: String(values['recipient.account']).slice(0, -1),
                                    contaDigito: values['recipient.account'][values['recipient.account'] - 1],
                                    carteira: '09',
                                    nossoNumero: '00000000061',
                                    nossoNumeroDigito: '8'
                                },
                                endereco: {
                                    bairro: values['recipient.neighborhood'],
                                    cep: values['recipient.zipCode'],
                                    cidade: values['recipient.city'],
                                    estadoUF: values['recipient.state'],
                                    logradouro: values['recipient.street'],
                                }
                            },
                            boleto: {
                                numeroDocumento: '1001',
                                especieDocumento: 'DM',
                                valor: Number((Math.abs(values?.value) * 100).toFixed(0)),
                                datas: {
                                    documentos: String(moment().format('YYYY-MM-DD')),
                                    processamento: String(moment().format('YYYY-MM-DD')),
                                    vencimento: String(moment(values.dueDate).format('YYYY-MM-DD'))
                                }
                            },
                            instrucoes: values.instructions
                        }, (isValid, msg) => {
                            if (!isValid) {
                                setErrorMessage(msg);
                            } else {
                                message.success('Bill created successfully 😄')
                                setErrorMessage('');
                            }
                        })
                    }}

                >
                    <Form.Item label="Nome" name="payer.name"
                        rules={[{
                            required: true, message: "Por favor informe o nome!"
                        }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item label="Código postal" name="payer.zipCode"
                        rules={[{ required: true, message: 'Por favor informe cep!' }]}
                    >
                        <Input onChange={(e) => getDataFromCep(e.currentTarget.value, 'payer')} />
                    </Form.Item>
                    <Form.Item label="Logradouro" name="payer.street"
                        rules={[{ required: true, message: 'Por favor informe Logradouro!' }]}
                    >
                        <Input disabled style={{ ...customDisabledStyle }} />
                    </Form.Item>
                    <Form.Item label="Bairro" name="payer.neighborhood"
                        rules={[{ required: true, message: 'Por favor informe Bairro!' }]}
                    >
                        <Input disabled style={{ ...customDisabledStyle }} />
                    </Form.Item>
                    <Form.Item label="Cidade" name="payer.city"
                        rules={[{ required: true, message: 'Por favor informe Cidade!' }]}
                    >
                        <Input disabled style={{ ...customDisabledStyle }} />
                    </Form.Item>
                    <Form.Item label="Estado" name="payer.state"
                        rules={[{ required: true, message: 'Por favor informe Estado!' }]}
                    >
                        <Input disabled style={{ ...customDisabledStyle }} />
                    </Form.Item>
                    <div style={{ margin: '100px 0 50px 0' }}>
                        <h2>Beneficiário</h2>
                        <hr></hr>
                    </div>

                    <Form.Item label="Nome" name="recipient.name"
                        rules={[{
                            required: true, message: "Por favor informe o nome!"
                        }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item label="CNPJ" name="recipient.cnpj"
                        rules={[{
                            required: true, message: "Por favor informe o CNPJ!"
                        }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item label="Agência" name="recipient.branch"
                        rules={[{
                            required: true, message: "Por favor informe a agência!"
                        }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item label="Conta" name="recipient.account"
                        rules={[{
                            required: true, message: "Por favor informe a conta!"
                        }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item label="Código postal" name="recipient.zipCode"
                        rules={[{ required: true, message: 'Por favor informe cep!' }]}
                    >
                        <Input onChange={(e) => getDataFromCep(e.currentTarget.value, 'recipient')} />
                    </Form.Item>
                    <Form.Item label="Logradouro" name="recipient.street"
                        rules={[{ required: true, message: 'Por favor informe Logradouro!' }]}
                    >
                        <Input disabled style={{ ...customDisabledStyle }} />
                    </Form.Item>
                    <Form.Item label="Bairro" name="recipient.neighborhood"
                        rules={[{ required: true, message: 'Por favor informe Bairro!' }]}
                    >
                        <Input disabled style={{ ...customDisabledStyle }} />
                    </Form.Item>
                    <Form.Item label="Cidade" name="recipient.city"
                        rules={[{ required: true, message: 'Por favor informe Cidade!' }]}
                    >
                        <Input disabled style={{ ...customDisabledStyle }} />
                    </Form.Item>
                    <Form.Item label="Estado" name="recipient.state"
                        rules={[{ required: true, message: 'Por favor informe Estado!' }]}
                    >
                        <Input disabled style={{ ...customDisabledStyle }} />
                    </Form.Item>

                    <div style={{ margin: '100px 0 50px 0' }}>
                        <h2>Boleto</h2>
                        <hr></hr>
                    </div>


                    <Form.Item label="Valor" name="value"
                        rules={[{
                            required: true, message: "Por favor informe o valor!"
                        }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item label="Vencimento" name="dueDate"
                        rules={[{
                            required: true, message: "Por favor informe o vencimento!"
                        }]}
                    >
                        <DatePicker />
                    </Form.Item>
                    <Form.Item label="Instruções" name="instructions"
                    >
                        <Input.TextArea placeholder='Insira instruções aqui ex: Após o vencimento Mora dia R$ 1,59' />
                    </Form.Item>
                    <Form.Item style={{ marginTop: 100 }}>
                        <Button type="primary" htmlType="submit" block loading={loading.create}>
                            Criar
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </>
    )
}


export default BillEmission
