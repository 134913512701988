import React from "react";
import { PersistGate } from "redux-persist/lib/integration/react";
import { getPersistor } from '@rematch/persist';
import { Provider } from 'react-redux';
import * as dayjs from 'dayjs';

import { store } from './store';
import { AppRoutes } from './routes';

dayjs.locale('pt-BR')

const persistor = getPersistor();

function App() {

  return (
    <PersistGate persistor={persistor}>
      <Provider store={store}>
        <AppRoutes />
      </Provider>
    </PersistGate>
  );
}

export default App;
