import React, { useEffect, useState } from 'react';
import { Button, Table, Card, Input, Menu, message, Tag, Space } from 'antd';
import Flex from '../../components/flex/';
import moment from 'moment';

import { DeleteOutlined, SearchOutlined, EditOutlined, PhoneOutlined } from '@ant-design/icons';
import { CustomerService } from '../../services/customer';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { RegexFactory } from '../../factory/regex.factory';
import { EllipsisDropdown } from '../../components/ellipsis-dropdown';


const App: React.FC = () => {
    const { pagination: customerPagination, loading } = useSelector((state: RootState) => state.customer);

    const [filter, setFilter] = useState({ name: '', contact: '' });
    const [pagination, setPagination] = useState({
        page: 1,
        perPage: 15,
    });

    const onFilter = async (clear = false) => {
        if (clear) {
            setFilter({ name: '', contact: '' });
        }

        // await leadService.getMany({ ...pagination, filter: clear ? {} as LeadTypes.Filters : { ...filter } });
    }

    useEffect(() => {
        CustomerService.listCustomers()

        console.log('here')
    }, [pagination]);

    return (<>
        <Card>
            <Flex alignItems="center" justifyContent="between" mobileFlex={false}>
                <>
                    <Flex className="mb-1" mobileFlex={false}>
                        <Space>
                            <div className="mr-md-6 mb-3">
                                <Input
                                    value={filter.name}
                                    // onChange={e => setFilter(f => ({ ...f, name: e.currentTarget.value }))}
                                    placeholder="Pesquisar por nome"
                                    prefix={<SearchOutlined />}
                                />
                            </div>
                            <div className="mr-md-6 mb-3 ml-2">
                                <Input
                                    value={filter.contact}
                                    // onChange={e => setFilter(f => ({ ...f, contact: e.currentTarget.value }))}
                                    placeholder="Pesquisar por telefone"
                                    prefix={<PhoneOutlined />}
                                />
                            </div>
                        </Space>
                    </Flex>
                    <Flex flexDirection='row-reverse'>
                        <Space>
                            <Button type="default" className='mr-2' block onClick={async () => await onFilter(true)}>Limpar filtro</Button>
                            <Button type="primary" block onClick={async () => await onFilter(false)}>
                                <span>Buscar</span>
                                <SearchOutlined />
                            </Button>
                        </Space>
                    </Flex>
                </>
            </Flex>
            <div className="table-responsive">
                {/* <p>{JSON.stringify(customerPagination.items)}</p> */}
                <Table
                    columns={[
                        {
                            title: 'ID',
                            dataIndex: 'id',
                        },
                        {
                            title: 'Nome',
                            dataIndex: 'name',
                        },
                        {
                            title: 'Cnpj',
                            dataIndex: 'businessCode',
                        },
                        {
                            title: 'Telefone',
                            dataIndex: 'phone',
                            // render: (_, dep) => RegexFactory.phoneNumber(dep.phone)
                        },
                        {
                            title: 'Data de criação',
                            dataIndex: 'createdAt',
                            render: (_, dep) => moment(dep.createdAt).format('DD/MM/YYYY HH:mm')
                        },
                        {
                            title: 'Última atualização',
                            dataIndex: 'updatedAt',
                            render: (_, dep) => moment(dep.updatedAt).format('DD/MM/YYYY HH:mm')
                        },
                        {
                            title: '',
                            dataIndex: 'actions',
                            render: (_, dep) => (
                                <div className="text-right">
                                    <EllipsisDropdown
                                        menu={
                                            <Menu>
                                                <Menu.Item key="0" onClick={
                                                    () => console.log('edit')
                                                    // () => onEditPress(dep)
                                                }>
                                                    <EditOutlined />
                                                    <span className="ml-2">Editar</span>
                                                </Menu.Item>
                                                <Menu.Divider />
                                                <Menu.Item key="1" onClick={() => {
                                                    // setSelectedLead(dep);
                                                    // onDeletePress();
                                                }}>
                                                    <DeleteOutlined />
                                                    <span className="ml-2">Excluir</span>
                                                </Menu.Item>
                                            </Menu>
                                        }
                                    />
                                </div>
                            )
                        }
                    ]}
                    dataSource={customerPagination.items}
                >
                </Table>
            </div>
        </Card>
    </>)
}


export default App