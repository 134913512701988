import React, { useState } from 'react';
import { Layout, Grid } from 'antd';
import { Outlet } from 'react-router-dom';

import utils from '../../utils';
import HeaderNav from './components/HeaderNav';
import SideBar from './components/SideBar';
import Footer from './components/Footer';

const { Content } = Layout;
const { useBreakpoint } = Grid;

const Main = () => {
  const [isVisible, setIsVisible] = useState(false);
  const screens = utils.getBreakPoint(useBreakpoint());
  const isMobile = !screens.includes('lg');

  const handleToggle = () => {
    setIsVisible((i) => !i);
  };

  const handleClose = () => setIsVisible(false);

  const getLayoutGutter = () => {
    if (isMobile) {
      return 0;
    }
    return isVisible ? 70 : 200;
  };

  const getLayoutDirectionGutter = () => ({ paddingLeft: getLayoutGutter() });

  return (
    <Layout className="dir-ltr">
      <HeaderNav toggleSideBar={handleToggle} sidebarCollapsed={isVisible} />
      <SideBar visible={isVisible} handleClose={handleClose} isMobile={isMobile} />
      <Layout className="app-container" style={getLayoutDirectionGutter()}>

        <Layout className="app-layout">
          <div className="app-content">
            <Content>
              <Outlet />
            </Content>
          </div>
          <Footer />
        </Layout>
      </Layout>
    </Layout>
  );
};

export default Main;
